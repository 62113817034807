export const colors = {
  text: '#011618',
  alpha: '#840032',
  beta: '#22304C',
  gamma: '#e28a8a',
  omega: '#f7f2f4',
};

export const fonts = {
  alpha: 'muli, sans-serif',
  beta: 'playfair, serif',
};

export const breakingpoints = {
  small: '@media screen and (max-width: 40rem)',
};
